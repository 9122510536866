<template>
  <v-container fluid class="pa-0">
    <div class="triangle"></div>
    <div class="content">
      <div class="_404">404</div>
      <div class="notfound">این صفحه دیگه وجود نداره!</div>
      <v-btn
        color="orange darken-1"
        dark
        elevation="0"
        class="rounded-lg"
        to="/"
      >
        بازگشت به صفحه اصلی
      </v-btn>
    </div>
    <v-img
      :src="require('../../assets/img/404.png')"
      class="_404-img rounded-xl mx-auto"
    />
  </v-container>
</template>

<script>
export default {
  mounted() {
    // if (window.location.href.indexOf("/404") != -1) window.location.href = "/404";
  },
};
</script>

<style scoped>
.triangle {
  width: 100vw;
  height: 0;
  border: 50vw solid transparent;
  border-bottom: 0;
  border-top: 50vh solid blue;
  position: absolute;
  z-index: 0;
}
.content {
  z-index: 10;
  position: relative;
  text-align: center;
}
._404 {
  font-size: 100px;
  white-space: 0;
  color: white;
}
.notfound {
  font-size: 20px;
  color: white;
}
._404-img {
  position: absolute;
  left: 25vw;
  right: 25vw;
  width: 50vh;
}
</style>
